@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  background-color: #e9eef2;
}
html {
  scroll-behavior: smooth;
}
h1 {
  color: #48b9db;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;
}
p {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: light;
  font-size: 12px;
}
li {
  @apply float-left uppercase;
  color: black;
  font-family: "Montserrat", sans-serif;
}
.especialidades {
  color: #5c5c5c;
  font-family: "Montserrat", sans-serif;
  font-weight: semibold;
  font-size: 12px;
}
.line {
  background-color: #48b9db;
  color: #48b9db;
}

.titleContainer {
  @apply py-5;
  color: #48b9db;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 40px;
}
.leaflet-container {
  width: 100%;
  height: 260px;
}
.menuSmall {
  max-width: 64px;
  max-height: 48px;
}
.menuBig {
  max-width: 4000px;
  max-height: 340px;
}
/* 
@media (min-width: 540px) {
  .titleContainer {
    font-size: 50px;
    height: 160px;
    width: 480px;
  }
}
*/
@media (min-width: 640px) {
  h1 {
    font-size: 30px;
  }
  p {
    font-size: 18px;
  }
  .especialidades {
    font-size: 18px;
  }
  .leaflet-container {
    height: 400px;
  }
}
@media (min-width: 1024px) {
  .titleContainer {
    font-size: 54px;
    height: 160px;
    width: 500px;
  }
  h1 {
    color: #48b9db;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 55px;
  }
  p {
    font-size: 22px;
  }
  .especialidades {
    font-size: 22px;
  }
  .leaflet-container {
    height: 500px;
  }
}

@media (min-width: 1440px) {
  /* .titleContainer {
    @apply pt-12;
    font-size: 96px;
    height: 290px;
    width: 824px;
  } */
  .leaflet-container {
    height: 600px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #e9eef2;
  }
  h1 {
    color: #48b9db;
  }
  p {
    color: black;
  }
  li {
    color: black;
  }
  .especialidades {
    color: #5c5c5c;
  }
  .line {
    background-color: #48b9db;
    color: #48b9db;
  }
  .titleContainer {
    color: #48b9db;
  }
}
